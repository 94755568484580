import React from 'react';
import { fragments } from './fragments';
import { yesNoOptions } from './utils';

export default {
  types: {
    job: {
      renderers: {
        inline: ({ node }) => <>{node.shortId} - {node.title}</>,
      },

      selections: {
        default: `
          ${fragments.job.basic}
          tasks { id task {
            ${fragments.task.basic}
          }}
          files(order_by: {file: {updatedAt: desc}}) { id file {
            ${fragments.file.basic}
          }}
          forms {id form{id title jobs {id job{id}}}}
          tags {id tag {id label type color icon}}
          firstWorkEntry: workEntries(limit: 1, order_by: [{start: asc}]){
            start end
            user {
              id displayName email
              avatar {id url mimeType name }
            }
          }
          lastWorkEntry: workEntries(limit: 1, order_by: [{start: desc}]){
            start end
            user {
              id displayName email
              avatar {id url mimeType name }
            }
          }
          workEntries_aggregate {
            aggregate {
              sum {
                duration
              }
            }
          }
          assignedUsers {id user {
            id displayName email
            avatar {id url mimeType name }
          }}
          expenses_aggregate{aggregate{count}}
          change_orders_aggregate: forms_aggregate(where: {form: {name:{_eq: "ChangeOrderForm"}}}){aggregate{count}}
          resources{id resource{id title description link createdAt updatedAt userId expires files}}
          resources_aggregate{aggregate{count}}
        `,
      },

      fields: {
        files: {
          type: 'file',
          hasMany: true,
        },

        shortId: {
          type: 'String',
          label: 'Code',
          validation: {
            required: true,
          },
        },

        title: {
          type: 'String',
          validation: {
            required: true,
          },
        },

        options: {
          type: 'Json',
        },

        specs: {
          type: 'Json',
        },

        customer: {
          type: 'contact',
          validation: {
            required: true,
          },
        },

        tasks: {
          type: 'task',
          hasMany: true,
          validation: {
            required: true,
          },
        },

        tags: {
          type: 'tag',
          hasMany: true,
          validation: { required: false },
        },

        forms: {
          type: 'form',
          hasMany: true,
          validation: { required: false },
        },

        assignedUsers: {
          type: 'user',
          hasMany: true,
          validation: { required: false },
        },

        resources: {
          type: 'resource',
          hasMany: true,
          validation: { required: false },
        },

        status: {
          type: 'String',
          options: [
            { value: 'active', primary: 'In Progress' },
            { value: 'requires-assessment', primary: 'Requires Assessment' },
            { value: 'quoted', primary: 'Quoted' },
            { value: 'completed', primary: 'Completed' },
            { value: 'invoiced', primary: 'Invoiced' },
            { value: 'archived', primary: 'Archived' },
          ],
          defaultValue: 'active',
          validation: {
            required: true,
          },
        },

        type: {
          type: 'String',
          validation: { required: true },
          options: [
            { value: 'refrigeration', primary: 'Refrigeration' },
            { value: 'heat-pump', primary: 'Heat Pump' },
            { value: 'gas-furnace', primary: 'Gas Furnace' },
            { value: 'pool-heater', primary: 'Pool Heater' },
            { value: 'ductless', primary: 'Ductless' },
            { value: 'central-ac', primary: 'Central A/C' },
            { value: 'ecolab-dishwasher', primary: 'Ecolab Dishwasher ' },
            { value: 'ecolab-dispenser', primary: 'Ecolab Dispensers' },
            { value: 'dishwasher', primary: 'Dishwasher' },
            { value: 'boiler', primary: 'Boiler' },
            { value: 'on-demand-water-heater', primary: 'On Demand Water Heater' },
            { value: 'hot-water-tank', primary: 'Hot Water Tank' },
            { value: 'vehicle-ac', primary: 'Vehicle A/C' },
            { value: 'boat-regrigeration', primary: 'Boat Refrigeration' },
            { value: 'fat-fryer', primary: 'Fat Fryer' },
            { value: 'gas-range', primary: 'Gas Range' },
            { value: 'gas-piping', primary: 'Gas Piping' },

            { value: 'oil-furnace', primary: 'Oil Furnance' },
            { value: 'piping-only', primary: 'Piping Only' },
            { value: 'venting-only', primary: 'Venting Only' },
            { value: 'no-heat-call', primary: 'No Heat Call' },
            { value: 'breakdown-call', primary: 'Breakdown Call' },
            { value: 'training', primary: 'Training' },
            { value: 'gas-fireplace', primary: 'Gas Fireplace' },

            { value: 'generic', primary: 'General', deleted: true },
            { value: 'hvac-ducts', primary: 'HVAC Ducts', deleted: true },
            { value: 'air-water-heat-pump', primary: 'Air-to-Water Heat Pump', deleted: true },
            { value: 'pool-heater', primary: 'Pool Heater', deleted: true },
            { value: 'quotes', primary: 'Quotes', deleted: true },
          ],
        },

        'specs.catalystStationNumber': { type: 'String', validation: { required: false } },
        'specs.btus': { type: 'String', label: 'BTUs', validation: { required: false } },
        'specs.gasPipingType': { type: 'String', label: 'Piping Type', validation: { required: false } },
        'specs.gasPressure': { type: 'String', label: 'Pressure', validation: { required: false } },
        'specs.make': { type: 'String', validation: { required: false } },
        'specs.model': { type: 'String', validation: { required: false } },
        'specs.filterSize': { type: 'String', validation: { required: false } },
        'specs.belts': { type: 'String', validation: { required: false } },
        'specs.serialNumber': { type: 'String', validation: { required: false } },
        'specs.refrigerantType': { type: 'String', validation: { required: false } },
        'specs.ferryRequired': {
          defaultValue: 'no',
          type: 'String',
          options: yesNoOptions,
          validation: { required: false },
        },
        'specs.callType': {
          type: 'String',
          options: [
            { value: 'service', primary: 'Service' },
            { value: 'new-install', primary: 'New Install' },
            { value: 'other', primary: 'Other' },
          ],
          validation: { required: true },
        },

        'specs.nameplatePhotos': { type: 'file', hasMany: true, embedded: true, validation: { required: false } },
        'specs.overviewPhotos': { type: 'file', hasMany: true, embedded: true, validation: { required: false } },

        'specs.permits': { type: 'String', validation: { required: false } },
        'specs.customerPo': { type: 'String', validation: { required: false } },

        'specs.depositReceivedVia': { type: 'String', validation: { requiredIf: { dep: 'specs.dateDepositReceived', isTruthy: true } } },
        'specs.dateDepositReceived': { type: 'String' },
        'specs.billingInfoSameAsCustomer': { type: 'String', options: yesNoOptions, defaultValue: 'yes', validation: { required: true } },
        'specs.billingInfo': { type: 'String', validation: { requiredIf: { dep: 'specs.billingInfoSameAsCustomer', eq: 'no' } } },
        'specs.billStatus': { type: 'String', defaultValue: 'not-billed', validation: { required: true } },

        'specs.notes': { type: 'String', validation: { required: false } },
        'specs.bookkeeperNotes': { type: 'String', validation: { required: false } },
        'specs.officeManagerNotes': { type: 'String', validation: { required: false } },

        'specs.boilerInfo': { type: 'String', validation: { required: false } },
        'specs.fireplaceInfo': { type: 'String', validation: { required: false } },
      },
    },
  },
};
