import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { Grid } from 'src/kiska/components/Grid';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

import { MutationContext } from 'src/kiska/components/MutationContext/MutationContext';
import TextField from 'src/kiska/components/MutationContext/FormFields/TextField';
import { NodeField } from 'src/kiska/components/MutationContext/FormFields/NodeField';
import DateTimePicker from 'src/kiska/components/MutationContext/FormFields/DateTimePicker';
// import DatePicker from 'src/kiska/components/MutationContext/FormFields/DatePicker';

import CrudForm from 'src/components/updates/CrudForm';
import { Stat } from 'src/components/Stat';
import { getDuration, findTaskOptionsForUserOnJob } from 'src/utils';
import { GridLineBreak } from 'src/components/forms';
import { useUser } from 'src/kiska/components/contexts/UserContext';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4, 2, 2, 2),
  },
  statCell: {
    marginTop: theme.spacing(-4),
    marginBottom: theme.spacing(-2),
  },
  stat: {
    color: green[500],
  },
  timeCell: {
    marginBottom: theme.spacing(-4),
  },
  other: {
    marginTop: theme.spacing(4),
  },
}));

const WorkEntryUpdateFormInner = (props) => {
  const { fixtures } = props;
  const classes = useStyles(props);
  const { onChange, registerField } = useContext(MutationContext);
  const { getFieldValue } = useContext(MutationContext);
  const { user: currentUser } = useUser();
  const allowedToEditWorkEntry = ['admin', 'manager', 'office-admin'].includes(currentUser.role.id);

  console.log(`Allowed to edit entry: ${allowedToEditWorkEntry}`);

  const start = getFieldValue('start');
  const end = getFieldValue('end');
  const duration = (getDuration(start, end) / 60 / 60);

  const job = getFieldValue('job');
  const user = getFieldValue('user');
  let tasksFieldKeyHash = '_';
  if (job && user) {
    tasksFieldKeyHash = `${user.id}_${job.id}`;
  }

  const taskOptions = useMemo(() => {
    let result = [];
    if (job && user) {
      result = findTaskOptionsForUserOnJob(user, job);
    }
    return result;
  }, [job, user]);

  const filterTaskOptions = (options) => {
    return options.filter((option) => !!taskOptions.find((taskOption) => option.id === taskOption.id));
  };

  const task = getFieldValue('task');
  const payRate = getFieldValue('payRate');
  useEffect(() => {
    if (task) {
      const taskOption = taskOptions.find((t) => task.id === t.id);
      if (!taskOption) {
        // Invlid task, clear it
        onChange('task', null);
      }
    }
  }, [onChange, payRate, task, taskOptions]);

  const injectPayRate = useCallback(async () => {
    if (!task) return null;
    const taskOption = taskOptions.find((t) => task.id === t.id);
    return taskOption ? taskOption.payRate : null;
  }, [task, taskOptions]);

  useEffect(() => {
    registerField('payRate', {
      forceReregister: true,
      initialValue: null,
      transforms: {
        formValueToMutationVar: injectPayRate,
        nodeValueToFormValue: () => null,
      },
    });
  }, [injectPayRate, registerField]);

  return (
    <div className={classes.root}>
      <Grid container spacing={2} alignItems="center" justify="center">
        <Grid item xs={12} container direction="column" alignItems="center" className={classes.statCell}>
          {end
            ? <Stat classes={{ root: classes.stat }} decimals={1} value={duration} label="HOURS" valueColor="inherit" labelColor="inherit" />
            : null /* <Typography variant="body2">&nbsp;</Typography> */}
        </Grid>

        {/** ************* Times ************** */}
        <Grid container item xs={12} alignItems="center" direction="row" justify="center" spacing={4}>
          <Grid item container alignItems="center" direction="column" className={classes.timeCell}>
            <Typography variant="h5" color="textSecondary" align="center">Start Time</Typography>
            <DateTimePicker name="start" label="" disableFuture pickerProps={{ openTo: 'hours' }} disabled={!allowedToEditWorkEntry} />
          </Grid>

          <Grid item container alignItems="center" direction="column" className={classes.timeCell}>
            <Typography variant="h5" color="textSecondary" align="center">End Time</Typography>
            <DateTimePicker name="end" label="" disableFuture pickerProps={{ openTo: 'hours' }} disabled={!allowedToEditWorkEntry} />
          </Grid>
        </Grid>

        {/** ************** Other ************** */}
        <Grid container item xs={12} sm={12} spacing={2} className={classes.other}>
          {(!fixtures || !fixtures.user) && (
            <Grid item xs={12} sm={6}>
              <NodeField name="user" disabled={!allowedToEditWorkEntry} />
            </Grid>
          )}

          <GridLineBreak />
          <Grid item xs={12} md={6}>
            <NodeField name="job" />
          </Grid>

          {/* {node && ( */}
          <Grid item xs={12} md={6}>
            <NodeField key={tasksFieldKeyHash} name="task" filterOptions={filterTaskOptions} />
          </Grid>
          {/* )} */}

          <Grid item xs={12} sm={12}>
            <TextField name="notes" rows={3} variant="outlined" />
          </Grid>
        </Grid>

      </Grid>
    </div>
  );
};

const WorkEntryUpdateForm = ({ id, trigger, variant, onClose, open, fixtures, initialValues }) => {
  const { user } = useUser();

  const allowDelete = ['manager', 'admin', 'office-admin'].includes(user.role.id);

  let schemaExtensions;
  if (variant === 'stop') {
    schemaExtensions = {
      types: {
        work_entry: {
          fields: {
            end: {
              label: 'Stop Time',
              validation: {
                required: true,
              },
            },
          },
        },
      },
    };
  }

  return (
    <CrudForm
      type="work_entry"
      id={id}
      trigger={trigger}
      onClose={onClose}
      open={open}
      renderRichTitle={(n) => (n ? `Edit Log Entry` : 'Log Work Time')}
      renderTitle={(n) => (n ? `Edit Log Entry` : 'Log Work Time')}
      maxWidth="md"
      fixtures={fixtures}
      initialValues={initialValues}
      allowDelete={allowDelete}
      schemaExtensions={schemaExtensions}
    >
      <WorkEntryUpdateFormInner variant={variant} fixtures={fixtures} />
    </CrudForm>
  );
};

WorkEntryUpdateForm.propTypes = {
  id: PropTypes.string,
  trigger: PropTypes.node,
  variant: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  fixtures: PropTypes.object,
  initialValues: PropTypes.object,
};

WorkEntryUpdateForm.defaultProps = {
  id: undefined,
  trigger: undefined,
  variant: undefined,
  onClose: undefined,
  open: undefined,
  fixtures: undefined,
  initialValues: undefined,
};

export { WorkEntryUpdateForm };
